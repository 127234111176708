<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="isHomeActive" class="pa-0">
      <v-sheet
        ref="sheet"
        color="#c5efea"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div
          class="text-alternate"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div class="pl-2 pr-2 pt-1">Texte à personnaliser</div>
          <v-btn
            ref="btn"
            color="#58827F"
            rounded
            depressed
            class="ma-2 white--text"
            @click.stop="showFeedbackModal = true"
          >
            <v-icon color="#ffffff" class="mr-2"> mdi-microphone </v-icon>
            Enregistrer une dédicace
          </v-btn>
          <w-feedback-popup id="firstFeedback" v-model="showFeedbackModal" />
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import WFeedbackPopup from '../components/WFeedbackPopup'

export default {
  components: {
    WFeedbackPopup,
  },
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'auth/name',
    }),
    isHomeActive() {
      return this.$route.name === 'index'
    },
  },
  methods: {
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__pre-heading {
  justify-content: center;
  text-align: center;

  .v-sheet {
    border-radius: 8px;
    text-align: left;
  }
  .v-sheet__desktop {
    & > div {
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;

      .v-btn {
        border-radius: 4px;
      }
    }
  }
}
.message_welcome_sir {
  border-radius: 6px !important;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .message_welcome_sir {
    flex-direction: column;
    align-items: center;
    div {
      margin-top: 15px;
    }
  }
}
</style>
