<template>
  <div class="w-block__footer">
    <!-- footer block -->
    <div class="body-2">
      Ici, nous pouvons des informations généralistes sur le programme<br />
      Un programme proposé par [votre logo]
    </div>
    <exc-dialog />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ExcDialog from '~/excentrics/components/ExcDialog'
export default {
  components: {
    ExcDialog,
  },
  computed: {
    ...mapGetters({
      isPersistantVisible: 'player/isPersistantVisible',
    }),
  },
  methods: {
    handleOpen(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
